<template>
  <section class="content">
    <div class="btn-box">
      <el-form :inline="true">
        <el-form-item label="主题名称：">
          <el-input v-model="search_name" placeholder="请输入商品id"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="search_status" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option label="显示" value="1"></el-option>
            <el-option label="隐藏" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="seach(1)">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add">添加</el-button>
    </div>
    <!-- 表格 -->
    <el-table v-loading="loading" :data="list" border>
      <el-table-column label="id" prop="id"></el-table-column>
      <el-table-column label="主题名称" prop="theme_name"></el-table-column>
      <el-table-column label="分类数量" prop="sort_num"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          {{ scope.row.status | status }}
        </template>
</el-table-column>
<el-table-column label="链接地址">
    <template slot-scope="scope">
          {{ scope.row.jump_url }}
          <el-button
            type="text"
            size="small"
            v-clipboard:copy="scope.row.jump_url"
            v-clipboard:success="copy"
            >复制</el-button
          >
        </template>
</el-table-column>
<el-table-column label="操作">
    <template slot-scope="scope">
          <el-button
            size="mini"
            :type="scope.row.status == 1 ? 'danger' : 'primary'"
            @click="updateStatus(scope.row.id, scope.row.status)"
            >{{ scope.row.status == 1 ? "停用" : "启用" }}</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="OneCommdityRecommend(scope.row.id)"
            >修改</el-button
          >
        </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange"></el-pagination>
<!-- 添加修改 -->
<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal="false" width="90%">
    <el-form label-width="70px" label-position="left" style="text-align: left">
        <el-row>
            <el-col :span="24">
                <el-form-item label="主题名称">
                    <el-input v-model="theme_name" placeholder="请输入" style="width: 300px"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item label="头图">
                    <div class="el-form-item__content mgb10">
                        <div style="width: 100%; color: transparent">s</div>
                        <upLoad dir="image/platform_page" id="up" @url="imgUrl" v-model="head_image"></upLoad>
                    </div>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item label="背景颜色">
                    <el-color-picker v-model="background_color"></el-color-picker>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item label="是否显示">
                    <el-radio-group v-model="status">
                        <el-radio :label="1">显示</el-radio>
                        <el-radio :label="2">隐藏</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item v-for="(item, i) in assembly_list" :key="'会场' + i" :label="'会场' + (i + 1)">
                    <span>位置：</span>
                    <el-select class="up" v-model="item.position_type" placeholder="请选择">
                        <el-option v-for="item in location_list" :key="item.type" :label="item.name" :value="item.type">
                        </el-option>
                    </el-select>
                    <span>类型：</span>
                    <el-select class="up" v-model="item.conference_type" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.type" :label="item.name" :value="item.type">
                        </el-option>
                    </el-select>
                    <span>链接：</span>
                    <el-input class="up" v-model="item.conference_value"></el-input>
                    <span>宣传图：</span>
                    <upLoadText class="inline" @url="itemUrl" :id="'up' + i" :upIndex="i" v-model="item.image_url"></upLoadText>
                    <span>权重：</span>
                    <el-input class="up w_50" v-model="item.weights" style="width: 50px"></el-input>
                    <span>备注：</span>
                    <el-input class="up" v-model="item.remark"></el-input>
                    <el-button type="" @click="remove(i, 'assembly')">X</el-button>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item>
                    <el-button @click="append('assembly')">添加会场</el-button>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item v-for="(item, index) in lassify_list" :key="index" :label="'分类' + (index + 1)">
                    <el-select v-model="item.type" placeholder="请选择分类类型" style="margin-right: 10px">
                        <el-option v-for="item in classTypeOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <span v-if="item.type == 1">分类名：</span>
                    <el-input v-if="item.type == 1" class="add" v-model="item.classify_name"></el-input>
                    <span>专题ID：</span>
                    <el-input class="add" v-model="item.materiel_id"></el-input>
                    <span>权重：</span>
                    <el-input class="add" v-model="item.weights" style="width: 50px"></el-input>
                    <el-button type="" @click="remove(index, lassify)">X</el-button>
                </el-form-item>
            </el-col>
        </el-row>
        <el-col :span="24">
            <el-form-item>
                <el-button type="" @click="append('lassify')">添加分类</el-button>
            </el-form-item>
        </el-col>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="postCommdityRecommend">确 定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    let common = JSON.parse(window.localStorage.getItem("common"));
    import * as api from "@/config/api";
    export default {
        name: "commodityRecommend",
        data() {
            return {
                list: [],
                loading: true,

                total: 0,
                page: 1,
                pagesize: 10,

                dialog: false,

                search_name: "",
                search_status: "",

                options: common.item_conference_hall_type_list,
                // 位置
                location_list: [{
                    name: ' ',
                    type: 0
                }, {
                    name: '会场顶部',
                    type: 1
                }, {
                    name: '会场左侧',
                    type: 2
                }, {
                    name: '会场右侧',
                    type: 3
                }, {
                    name: '会场中间',
                    type: 4
                }, {
                    name: '热门会场',
                    type: 5
                }],

                id: "",
                theme_name: "",
                head_image: "",
                background_color: "",
                status: 1,
                position_type: 0,
                // 分类
                lassify_list: [{
                    classify_name: "",
                    materiel_id: "",
                    weights: "",
                    type: undefined,
                }, ],
                // 会场
                assembly_list: [{
                    position_type: "0",
                    conference_type: "",
                    conference_value: "",
                    weights: "",
                    image_url: "",
                    remark: "",
                }, ],
                // 分类类型
                classTypeOptions: [{
                    value: 1,
                    label: "自定义分类",
                }, {
                    value: 2,
                    label: "自有分类",
                }, ],
            };
        },
        filters: {
            status(val) {
                let name = "";
                switch (Number(val)) {
                    case 1:
                        name = "显示";
                        break;
                    case 2:
                        name = "隐藏";
                        break;
                }
                return name;
            },
        },
        mounted() {
            this.getCommdityRecommend();
        },
        methods: {
            copy() {
                this.$message({
                    message: "复制成功",
                    type: "success",
                });
            },
            append(name) {
                console.log(name);
                if (name == "assembly") {
                    this.assembly_list.push({
                        conference_type: "",
                        conference_value: "",
                        weights: "",
                        image_url: "",
                        remark: "",
                    });
                    return;
                }
                this.lassify_list.push({
                    classify_name: "",
                    materiel_id: "",
                    weights: "",
                    type: undefined,
                });
            },
            remove(index, name) {
                if (index !== -1) {
                    if (name == "assembly") {
                        this.assembly_list.splice(index, 1);
                        return;
                    }
                    this.lassify_list.splice(index, 1);
                }
            },
            // 分页
            sizeChange(val) {
                this.pagesize = val;
                this.getCommdityRecommend();
            },
            currentChange(val) {
                this.page = val;
                this.getCommdityRecommend();
            },
            seach(page, size) {
                this.getCommdityRecommend(page, size);
            },
            itemUrl(val) {
                this.assembly_list[val.index].image_url = val.url;
            },
            imgUrl(val) {
                this.head_image = val;
            },
            // 搜搜
            seach(page) {
                this.getCommdityRecommend(page);
            },
            // 获取商品配置列表
            getCommdityRecommend(page, size) {
                this.loading = true;
                api.getCommdityRecommend({
                        theme_name: this.search_name,
                        status: this.search_status,
                        page: page ? page : this.page,
                        pagesize: size ? size : this.pagesize,
                    },
                    (res) => {
                        this.list = res.data.list;
                        this.total = Number(res.data.count);
                        this.loading = false;
                    }
                );
            },
            // 修改配置列表状态
            updateStatus(id, status) {
                api.updateCommdityRecommendStatus({
                        id: id,
                        status: status == 1 ? 2 : 1,
                    },
                    (res) => {
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        this.getCommdityRecommend();
                    }
                );
            },
            // 获取单个商品配置
            OneCommdityRecommend(id) {
                this.id = id;
                api.getOneCommdityRecommend({
                        id: id,
                    },
                    (res) => {
                        res.data.conference_list.forEach((item) => {
                            item.conference_type = item.conference_type.toString();
                            console.log(item);
                        });
                        this.theme_name = res.data.theme_name;
                        this.head_image = res.data.head_image;
                        this.background_color = res.data.background_color;
                        this.status = res.data.status;
                        this.lassify_list = res.data.lassify_list;
                        this.assembly_list = res.data.conference_list;
                        this.dialog = true;
                    }
                );
            },
            // 打开添加弹窗
            add() {
                this.id = "";
                this.theme_name = "";
                this.head_image = "";
                this.background_color = "";
                this.status = 1;
                this.lassify_list = [{
                    classify_name: "",
                    materiel_id: "",
                    weights: "",
                    type: undefined,
                }, ];
                this.assembly_list = [{
                    position_type: 0,
                    conference_type: "",
                    conference_value: "",
                    weights: "",
                    image_url: "",
                    remark: "",
                }, ];
                this.dialog = true;
            },
            // 更新、添加
            postCommdityRecommend() {
                let classType2Num = 0;
                for (let i = 0; i < this.lassify_list.length; i++) {
                    if (this.lassify_list[i].type == 2) {
                        classType2Num++;
                        if (classType2Num >= 2) {
                            this.$message({
                                message: "自有分类只能有一个",
                                type: "error",
                            });
                            return;
                        }
                    }

                    if (this.lassify_list[i].type === undefined) {
                        this.$message({
                            message: "请选择分类类型",
                            type: "error",
                        });
                        return;
                    }
                }
                if (this.id) {
                    api.updateCommdityRecommend({
                            id: this.id,
                            theme_name: this.theme_name,
                            head_image: this.head_image,
                            background_color: this.background_color,
                            status: this.status,
                            classify_arr: this.lassify_list,
                            conference_arr: this.assembly_list,
                        },
                        (res) => {
                            this.$message({
                                message: res.msg,
                                type: "success",
                            });
                            this.getCommdityRecommend();
                            this.dialog = false;
                        }
                    );
                    return;
                }
                api.addCommdityRecommend({
                        theme_name: this.theme_name,
                        head_image: this.head_image,
                        background_color: this.background_color,
                        status: this.status,
                        classify_arr: this.lassify_list,
                        conference_arr: this.assembly_list,
                    },
                    (res) => {
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        this.getCommdityRecommend();
                        this.dialog = false;
                    }
                );
            },
        },
    };
</script>
<style scoped lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    img {
        height: 40px;
    }
    
    .add {
        width: calc(100% / 3 - 100px);
        margin-right: 10px;
    }
    
    .up {
        width: calc(100% / 4.5 - 100px);
        margin-right: 10px;
        &.w_50 {
            width: 50px;
        }
    }
    
    .inline {
        display: inline-block;
        margin-right: 10px;
    }
    
    .inline .img-box img {
        width: 100%;
    }
</style>